<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="generic-wizard-heading">Select A Category</div>
        <p class="generic-wizard-subheading">Don’t worry you are just a few clicks away.</p>
      </div>
    </div>
    <categories />
  </div>
</template>

<script>
import categories from "../../components/categories.vue";

export default {
  components: {
    categories,
  },
  metaInfo: {
    title: "Categories | KIPS VIRTUAL",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {};
  },
};
</script>
